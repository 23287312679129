<template>
    <div class="run">
    <div class="run_title">📢📢_的<span style="color: #f44336;">10</span>公里✏️</div>
        <div class="demo-vsteper-container">
        <mu-stepper orientation="vertical" :active-step="vactiveStep">
        <mu-step v-for="(item,index) in history" :key="index">
            <mu-step-label>
             <div class="run_time"><span class="run_clock">🕤</span>{{item.time}}
                  <div class="run_date">{{item.date}}</div>

             </div>
             <div v-if="index!=0" class="run_every" :style="`height: ${50}px;  left:33.33333vw;  top:-${(history.length-index)*10+10}px`">
                <div class="run_deff_wrap">
                    <div class="run_deff">
                        <span class="run_flag">{{hourDiffOr(history[index-1].time, history[index].time) ?'🎀':'🍃'}}</span>
                        <div>≈{{hourDiff(history[index-1].time, history[index].time)}}m</div>
                        <div>{{dateDiff(history[index-1].date, history[index].date)}}d</div>
                    </div>
                </div>
             </div>
             <div :style="`height: ${71*(history.length-index)}px; right:${10*index+5}px`" class="run_tag" v-if="index != history.length-1">
                <div class="run_total_wrap">
                    <div class="run_total">
                        <div>≈{{hourDiff(history[index].time, history[history.length-1].time)}}m</div>
                        <div>{{dateDiff(history[index].date, history[history.length-1].date)}}d</div>
                    </div>
                </div>
            </div>
            </mu-step-label>
        </mu-step>
        </mu-stepper>
    </div>
          <div class="run_sen">
            随风奔跑自由是飞翔~
            <div>累计奔跑<span class="run_specile" >{{10 * history.length}}</span>公里</div>
          </div>
    </div>
  </template>
  <script>

const history = [
    {
      time: '1:03:14',
      date: '2022-11-05'
    },
    {
      time: '1:05:10',
      date: '2022-10-22'
    },
    {
      time: '1:08:46',
      date: '2022-09-27'
    },
    {
      time: '1:15:59',
      date: '2022-09-22'
    },
    {
      time: '1:15:58',
      date: '2022-09-20'
    },
 
]

  
  export default {
    data(){
      return {
        vactiveStep:history.length,
        history
      }
    },
    methods:{
        dateDiff(strDateStart, strDateEnd) {
            console.log(strDateStart,'strDateStart')
            let strSeparator = "-";
            let iDays;
            let oDate1 = strDateStart.split(strSeparator);
            let oDate2 = strDateEnd.split(strSeparator);
            let strDateS = new Date(oDate1[0], oDate1[1] - 1, oDate1[2]);
            let strDateE = new Date(oDate2[0], oDate2[1] - 1, oDate2[2]);
            iDays = parseInt(Math.abs(strDateS - strDateE) / 1000 / 60 / 60 / 24)
            return iDays;
        },
        hourDiff(s1, s2) {
            var reDate = /\d{4}-\d{1,2}-\d{1,2} /;
            s1 = new Date((reDate.test(s1) ? s1 : '2018-1-1 ' + s1).replace(/-/g, '/'));
            s2 = new Date((reDate.test(s2) ? s2 : '2018-1-1 ' + s2).replace(/-/g, '/'));
            var ms = s2.getTime() - s1.getTime();
            if (ms < 0) {
                ms = -(ms)
                return  -Math.round(ms / 1000 / 60)
            }
            return Math.round(ms / 1000 / 60)
        },
        hourDiffOr(s1, s2) {
            var reDate = /\d{4}-\d{1,2}-\d{1,2} /;
            s1 = new Date((reDate.test(s1) ? s1 : '2018-1-1 ' + s1).replace(/-/g, '/'));
            s2 = new Date((reDate.test(s2) ? s2 : '2018-1-1 ' + s2).replace(/-/g, '/'));
            var ms = s2.getTime() - s1.getTime();
            if (ms < 0) {
               return false
            }
            return true
        }
    },
    mounted(){
     
  
    }
  }
  </script>
  <style lang="less">
    @font-face {
        font-family: title;
        src: url('../assets/run_title.ttf')
    }
    @font-face {
        font-family: content;
        src: url('../assets/run_content.ttf')
    }
  .run {
    background-image: url('../assets/images/run_bg.png');
    height: 100vh;
    background-size: cover;
    background-attachment: fixed;
    color: #222;
  }
  .run_flag {
    position: absolute;
    left: -22px;
    top: -2px;
  }
    .run_title {
        font-size: 22px;
        padding: 20px;
        padding-bottom: 10px;
        font-family: title;
        color: rgba(53, 48, 77, 0.89);
        font-weight: bolder;
    }
    .run_time {
        font-family: content;
        font-size: 18px;
        position: relative;
        color: rgb(66, 66, 66);
        font-weight: bolder;
    }
    .run_date {
        color: red;
        position: absolute;
        width: 300px;
        font-size: 12px;
        color: rgb(128, 128, 128);
    }
    .run_tag {
        width: 5px;
        border-left: 1px dotted rgb(48, 50, 83);
        position: absolute;
        top:0;
    }
    .mu-step-label {
        position: relative;
    }
    .run_every {
        border-left: 1px dotted rgb(48, 50, 83);
        // background: #222;
        position: absolute;
    }
    .run_deff_wrap {
        position: relative;
        z-index: 9;
        font-size: 12px;

        color: rgb(128, 128, 128);
    }
    .run_deff {
        position: absolute;
        width: 300px;
        padding-left: 10px;
    }
    .run_clock {
        position: absolute;
        left: -34px;
    }
    .run_total_wrap {
        position: relative;
        font-size: 12px;
        color: rgb(24, 24, 24);
    }
    .run_total {
        text-align: right;
        position: absolute;
        width: 40px;
        left: -50px;
    }
    .run_sen {
        font-size: 16px;
        text-align: right;
        position: fixed;
        bottom: 150px;
        right: 5px;
        letter-spacing: 10px;
        font-family: title;
        color: rgb(107, 107, 107);
    }
    .run_specile {
        font-size: 30px;
        text-decoration: underline;
        color: rgb(233, 29, 29);
    }
  </style>