<template>
  <div class="ran">

         <div  class="rainTitte">
          <mu-text-field v-model="titlee" placeholder="输 入 一 个 有 趣 的 标 题"  full-width   ></mu-text-field>
         </div>
         <div class="form1" >
         <div class="checkPlace">
          <mu-button fab color="#2196f3" small class="pos" @click="submit" >
            <mu-icon value="check" ></mu-icon>
            </mu-button>
         </div>
            <mu-text-field :error-text="errorText" v-model="title" placeholder="请 输 入 随 机 选 项"  full-width solo  max-length="15"></mu-text-field>
        </div>

        <div class="listWrap">
            <div  v-for="(item, key) in list" :key="key" class="listLine">
                  <span class="listText">{{key+1}}.{{item}}</span>
                  <div class="close" @click="dele(key)">x</div>
            </div>
        </div>

        <div class="chong" v-if="list.length>1">

             <div v-if="resTitle">
               <div  class="resTitle">
                  {{resTitle}}
              </div>

             

              <div class="dateText">{{date}}</div>

             </div>
              <div v-if="isLoading" >
                <img src="@/assets/loading1.gif" alt="" class="loadingimg">
              </div>
             <mu-button  color="rgb(0, 150, 136)" :full-width="true" large  class="pos" @click="getRes" >
                一 言 为 定 ！ 冲~
             </mu-button>
        </div>

        <div v-if="list.length==0" class="empty">
          <img  src="@/assets/empty.png" >

           <div class="look" @click="seeHistory">
            <mu-button fab color="success" small>
              <mu-icon value="edit" ></mu-icon>
            </mu-button>
          </div>
        </div>


          <mu-drawer :open.sync="openDrawer" right :docked="false">
            <div class="choosContent">- 生 活 中 的 仪 式 感 -</div>
            <mu-list>
              <mu-list-item button v-for="(item, key) in historyList" :key="key" @click="chooseList(key)">
                <mu-list-item-content>
                  <mu-list-item-title>{{key+1}}、{{item.title}}</mu-list-item-title>
                  <mu-list-item-sub-title>{{item.content.join('、')}}</mu-list-item-sub-title>
                </mu-list-item-content>
              </mu-list-item>
            </mu-list>
          </mu-drawer>
      
  </div>
</template>
<script>

const history = [
    {
      title: '下雨放学干什么',
      content: ['乒乓球', '攀岩', '游泳']
    },
    {
      title: '中午吃什么',
      content: ['楼下食堂', '外卖']
    },
    {
      title: '奶茶喝什么',
      content: ['一点点', '古茗', '茶百道']
    },
    {
      title: '周末先去哪个商场',
      content: ['银泰', '吾角天街', '紫金天街']
    },
    {
      title: '周末吃什么',
      content: ['牛蛙', '烤鱼', '大虾','火锅','烤肉']
    },
     {
      title: '去哪里抓娃娃',
      content: ['星乐码头', '第一回合', 'i玩嘉年华']
    },
     {
      title: '周末要不要躺平呢？',
      content: ['要', '不要', '不知道']
    },
]

import {icons} from '../utils/decoration'
import { formatDate } from '../utils'
export default {
  
  data(){
    return {
        icons,
        ismoHu:false,
        open: false,
        openAn:false,
        currentIcon:0,
        errorText: '',
        title:'',
        list:[],
        isLoading:false,
        resTitle:'',
        date:'',
        titlee:'',
        openDrawer:false,
        historyList:history
    }
  },
  methods:{
    chooseList(key){
       let copy = JSON.parse(JSON.stringify(this.historyList))
      this.list = copy[key].content
      this.titlee = copy[key].title
      this.openDrawer = false
      console.log(this.historyList[key].content, 999)
    },
    seeHistory(){
      this.openDrawer=true
      this.resTitle=''
    },
       add(){
        this.open = true
    },
     close(){
      this.openAn = false
      this.ismoHu = false
      setTimeout(()=>{
        this.open = false
      }, 500)
    },
        submit(){
      if(!this.title) {
        this.$toast.error('选项不能为空');
        return
      }
      this.list.push(this.title)
      this.title = ''
      this.resTitle=''
    },
    dele(key){
      this.list.splice(key,1)
      this.resTitle = ''
    },
    getRes(){
       if(this.isLoading) return

        this.resTitle = ''
        this.isLoading = true
        let copy = JSON.parse(JSON.stringify(this.list))
       setTimeout(()=>{
        copy.sort(function () {
        return Math.random() - 0.5
        })
        this.isLoading=false
         this.resTitle =copy[0]
       }, 3000)
    }
  },
  mounted(){
    const date = new Date()
    const day = formatDate(date, 'yyyy-MM-dd hh:ss:mm')
    this.date = day

  }
}
</script>
<style lang="less">
.mu-list {
  li {
    margin-bottom: 10px;
  }
}
.choosContent {
  padding-bottom: 0;
  background: rgb(21, 101, 192);
  text-align: center;
  padding: 10px 0;
  color: #fff;
  font-size: 18px;

}
.dateText {
  font-size: 12px;
  position: absolute;
  bottom: 65px;
  right: 10px;
  color: #ccc;

}
.rainTitte {
  font-size: 50px;
  position: absolute;
  font-weight: bolder;
  line-height: 50px;
  top: 10px;
  padding: 0 20px;
  width:100%;
}

.mu-text-field-input {
  font-size: 30px;
  color: #ccc;
   height: 50px;
}

@font-face {
    font-family: cut;
    src: url('https://img.ok-bug.com/fonts/b.ttf')
}
.loadingg {
    width: 90px;
}
.listWrap {
    padding: 20px; 
    font-family: cut;
}
    .add1{
        position: absolute;
        right: 20px;
        top:10px;
    }
.listText {
        font-size: 30px;
}
.chong {
    width: 100%;
    text-align: center;
    position: fixed;
    bottom: 0;
    .mu-button {
      padding: 20px 0;
      height: 60px;
    }
}
.result {
    margin-top: 20px;
}


.form1 {
  width: 90%;
  margin: 0 auto;
  background: #fff;
  padding: 20px 10px;
  border-radius: 10px;
  margin-top: 100px;
  position: relative;
}

.checkPlace {
  position: absolute;
  right: 0px;
  top:-20px;
}
.resTitle {
  font-size: 40px;
  padding-bottom: 20px;
  font-family: cut;
  color: rgb(244, 67, 54);
}

.listLine {
  position: relative;
  .close {
    position: absolute;
    right: 10px;
    top: 10px;
    color: #ff4081;
}
}

.loadingimg {
  width: 100px;
  border-radius: 50%;
}

.empty {
  text-align: center;
  position: relative;
  img {
    width: 90%;
    border-radius: 10px;
  }
}

.look {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 0px;

}

</style>